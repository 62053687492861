













import { Vue, Component, Ref } from 'vue-property-decorator'
import PageTitle from '@/components/common/pageTitle.vue'
import CollectionContent from './CollectionContent.vue'

@Component({ components: { PageTitle, CollectionContent } })
export default class VisitorRecords extends Vue {
  @Ref('unregistered') unregistered: CollectionContent
  @Ref('registered') registered: CollectionContent

  private value = localStorage.getItem('ga-ip') || ''
  private ipReg = /(\d+\.){3}\d+(:(\d){2,5}){0,1}/

  refresh() {
    this.unregistered && this.unregistered.fetchPersonList()
    this.registered && this.registered.fetchPersonList()
  }

  submit() {
    if (this.ipReg.test(this.value)) {
      localStorage.setItem('ga-ip', this.value)
      this.$message({
        message: '配置成功',
        type: 'success'
      })
      this.refresh()
    } else {
      this.$message({
        message: '请输入有效的ip',
        type: 'error'
      })
    }
  }
}
