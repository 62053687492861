

























































































































































































































import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import FormDrawer from '@/components/common/FormDrawer/index.vue'
import { validate, identityNumber, telNumber, is } from '@/utils/helpers'
import { ElForm } from 'element-ui/types/form'
import { DatePickerOptions } from 'element-ui/types/date-picker'
import { information, resident } from '@/utils/options'
import { treeFilter } from 'operation-tree-node'
import { compressImg, imageRotate } from '@/components/common/compressImg'
import _ from 'lodash'
import mitt from '@/utils/mitt'
import { axios } from '@/api'

@Component({ components: { FormDrawer } })
export default class ResidentDrawer extends Vue {
  @Prop({ default: false }) visible: boolean
  @Prop({ default: 'create' }) mode: 'create' | 'update'
  @Prop({ default: () => ({}) }) data: AnyObj

  @Ref() form: ElForm

  loading = false

  houseList: AnyObj[] = []
  landlordHouseList: AnyObj[] = []
  houseProps = { label: 'name', value: 'id' }
  roomList: AnyObj[] = []
  datePickerOptions: DatePickerOptions = {
    disabledDate: time => time.getTime() < Date.now(),
    shortcuts: [
      { text: '3个月', onClick: picker => picker.$emit('pick', this.$moment().add(3, 'month')) },
      { text: '6个月', onClick: picker => picker.$emit('pick', this.$moment().add(6, 'month')) },
      { text: '12个月', onClick: picker => picker.$emit('pick', this.$moment().add(12, 'month')) }
    ]
  }

  formData: AnyObj = {}
  private tipShow = false
  private tipText = ''
  imageData: string | null = null
  imageUrl: string | null = null
  telType = 'phone'
  dynamicIp?: string

  get isCreationMode() {
    return this.mode === 'create'
  }

  get isUpdateMode() {
    return this.mode === 'update'
  }

  get title() {
    return this.isUpdateMode ? '编辑住户' : '新建住户'
  }

  get user() {
    return this.$store.state.user || {}
  }

  get isLandlord() {
    return this.formData.type === 'landlord'
  }

  get isRenter() {
    return this.formData.type === 'renter'
  }

  get areaList() {
    const areaList: AnyObj[] = this.$store.state.areas.areas
    return treeFilter(areaList, node => node.statistics.houses > 0)
  }

  get identityTypes() {
    return resident.identityTypes
  }

  get nationalities() {
    return information.nationalities
  }

  get politicals() {
    return information.politicals
  }

  get identity() {
    for (const { value } of resident.identityTypes) {
      if (this.formData[value]) {
        return { identityType: value, identityValue: this.formData[value] }
      }
    }

    return { identityType: 'identityNumber', identityValue: '' }
  }

  get rules() {
    return {
      name: [{ required: true, message: '请输入住户姓名', trigger: 'blur' }],
      type: [{ required: true, message: '请选择住户类型', trigger: 'blur' }],
      identityValue: [
        { required: true, message: '请输入证件号', trigger: 'blur' },
        {
          validator: (rule: any, value: string, callback: Function) => {
            const validator = (validate as Record<string, (v: string) => boolean>)[
              this.formData.identityType
            ]

            if (validator && !validator(value)) {
              callback(new Error('请输入正确的证件号'))
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }
      ],
      areaCode: [
        { required: true, message: '请输入区号', trigger: 'blur' },
        {
          validator: (rule: any, value: string, callback: Function) => {
            if (this.telType === 'landline' && !/^0\d{2,3}$/.test(value))
              callback(new Error('请输入正确的区号'))
            else callback()
          },
          trigger: 'blur'
        }
      ],
      tel: [
        {
          validator: (rule: any, value: string, callback: Function) => {
            if (!value) return callback()

            const type = this.telType
            if (type === 'phone' && !validate.phoneNumber(value))
              callback(new Error('请输入正确的手机号码'))
            if (type === 'landline' && !validate.simplifiedLandlineNumber(value))
              callback(new Error('请输入正确的座机号码'))
            else callback()
          },
          trigger: 'blur'
        }
      ],
      areaId: [{ required: true, message: '请选择居住地区', trigger: 'blur' }],
      roomId: [{ required: true, message: '请选择居住房屋房间', trigger: 'blur' }],
      houseIds: [
        { required: true, message: '请选择名下房屋', trigger: 'blur' },
        {
          validator: (rule: any, value: string[], callback: Function) => {
            if (value.length === 0) callback(new Error('请选择名下房屋'))
            else callback()
          },
          trigger: 'blur'
        }
      ],
      expireAt: [{ required: true, message: '请选择有效期', trigger: 'blur' }]
    }
  }

  get fields() {
    return [
      'imageUrl',
      'imageData',
      'type',
      'roomId',
      'houseIds',
      'expireAt',
      'profession',
      ...resident.identityTypes.map(({ value }) => value),
      ...this.updatableFields
    ]
  }

  get updatableFields() {
    return [
      'name',
      'cardNumber',
      'nationality',
      'tel',
      'political',
      'description',
      'plateNumber',
      'gender'
    ]
  }

  get extraFields() {
    return ['identityType', 'identityValue', 'areaId', 'houseId', 'areaCode']
  }

  get params() {
    const { identityType, identityValue } = this.formData

    const params: AnyObj = {
      ...this.formData,
      [identityType]: identityValue,
      imageUrl: this.formData.imageUrl || this.imageUrl,
      imageData: this.imageData
    }

    if (validate.identityNumber(identityValue)) {
      params.gender = identityNumber.getGender(identityValue)
    }

    if (this.isUpdateMode) {
      return _.pick(params, this.updatableFields)
    }

    return _.pick(params, this.fields)
  }

  created() {
    this.$store.dispatch('areas/fetchAreas')
    this.fetchIp()
  }

  mounted() {
    this.initialize()
  }

  private fetchIp() {
    this.dynamicIp = localStorage.getItem('ga-ip') || undefined
  }

  private focusInput() {
    this.tipShow = false
    this.tipText = ''
  }

  private checkUrl(url: string | null) {
    const regUrl = /^(ht|f)tps?:\/\/.*/

    if (!url) {
      this.tipShow = true
      this.tipText = '网址为空，请重新输入'
      return false
    } else if (!regUrl.test(url)) {
      this.tipShow = true
      this.tipText = '网址无效，请重新输入'
      return false
    }
    return true
  }

  private loadPic() {
    const flag = this.checkUrl(this.imageUrl)
    if (flag) this.imageData = this.imageUrl
  }

  private uploadHeadImg() {
    const input: any = this.$refs.hiddenInput
    input.click()
  }

  private handleFile(e: any) {
    this.compress(e.target.files ? e.target.files[0] : null)
    this.imageUrl = null
    this.tipShow = false
  }

  private async compress(file: File) {
    if (!file) {
      return
    }
    const result = await compressImg(file)
    result.onload = (e: any) => {
      imageRotate(e.target.result, this.getImage)
    }
    const input: any = this.$refs.hiddenInput
    input.value = ''
    return
  }

  private getImage(data: string) {
    this.imageData = data
  }

  async initialize() {
    this.formData = { ...this.data }

    const { tel } = this.formData
    const { type, areaCode, number } = telNumber.analysis(tel)
    this.telType = type

    this.setFormData({ ...this.identity, areaCode, tel: number })
    this.houseList = await this.fetchHouseList()
  }

  close() {
    this.form.resetFields()
    this.formData = {}
    this.landlordHouseList = []
    this.houseList = []
    this.roomList = []
    this.visible = false
    this.tipShow = false
    this.imageData = null
    this.imageUrl = null
  }

  async createResident() {
    if (!this.dynamicIp) this.fetchIp()
    const params = _.omit(this.params, ['imageUrl', 'imageData'])
    const { data } = await axios.post(
      `http://${this.dynamicIp}/bulk/person/info/register`,
      _.pickBy(params, is.ava)
    )

    if (data.code === 200) {
      this.$message({ message: '登记成功', type: 'success' })
      this.$emit('created', this.data)
      this.close()

      mitt.emit('residents:change')
    }
  }

  async updateResident() {
    const { data } = await this.$api.europa.putPerson(
      this.data.personId,
      _.mapValues(this.params, v => (is.ava(v) ? v : null))
    )

    if (data.code === 0) {
      this.$emit('updated', this.data)

      this.$message({ message: '编辑成功', type: 'success' })
      this.close()
    }
  }

  submit() {
    this.form.validate(async valid => {
      if (valid) {
        this.loading = true

        if (this.isCreationMode) {
          await this.createResident()
        } else {
          await this.updateResident()
        }

        this.loading = false
      }
    })
  }

  async fetchHouseList({ name = '', offset = 0, ids = [] as string[] } = {}) {
    const { areaId } = this.formData

    if (areaId) {
      const limit = 100

      const { data } = await this.$api.europa.getHouseLists(
        _.pickBy({ areaIds: [areaId], limit, offset, name, ids, fuzzy: true }, is.ava)
      )

      if (data.code === 0) {
        return data.data
      }
    }

    return []
  }

  async fetchRoomList(houseId: string) {
    const { data } = await this.$api.europa.getRooms({ houseIds: [houseId] })

    if (data.code === 0) {
      this.roomList = data.data

      // 选中的房屋只有一个房间时，默认选中这个房间
      if (this.roomList.length === 1) {
        this.setFormData({ roomId: this.roomList[0].id })
      }
    }
  }

  setFormData(formData: AnyObj) {
    this.formData = { ...this.formData, ...formData }
  }

  handleTypeChange(value: 'landlord' | 'relative' | 'renter') {
    this.form.clearValidate()

    if (value === 'landlord') {
      this.setFormData({ houseId: '', roomId: '' })
    } else {
      this.setFormData({ houseIds: [] })
    }

    if (value !== 'renter') {
      this.setFormData({ expireAt: null })
    }
  }

  handleTelTypeChange(value: 'phone' | 'landline') {
    this.telType = value
    this.setFormData({ tel: '', areaCode: '' })
    this.form.clearValidate(['tel', 'areaCode'])
  }

  async handleAreaChange() {
    this.houseList = await this.fetchHouseList()

    this.setFormData({ houseId: '', houseIds: [], roomId: '' })
    this.handleHouseChange('')
  }

  async handleHousesChange() {
    const { houseIds } = this.formData

    if (houseIds.length > 0) {
      this.landlordHouseList = await this.fetchHouseList({ ids: houseIds })
    } else {
      this.landlordHouseList = []
    }

    if (!houseIds.includes(this.formData.houseId)) {
      this.setFormData({ houseId: '', roomId: '' })
    }
  }

  handleHouseChange(houseId: string) {
    if (houseId) {
      this.fetchRoomList(houseId)
    } else {
      this.roomList = []
    }

    this.setFormData({ roomId: '' })
  }
}
