





































import { Vue, Component } from 'vue-property-decorator'
import FormDrawer from '@/components/common/FormDrawer/index.vue'
import { resident, options } from '@/utils/options'
import { phoneNumber } from '@/utils/helpers'

@Component({ components: { FormDrawer } })
export default class ImageDrawer extends Vue {
  private data: AnyObj = {}
  private visible = false
  private loading = false

  private get type() {
    return options(resident.types).find(this.data.type) ?? {}
  }

  private get tel() {
    return phoneNumber.simplify(this.data.tel ?? '')
  }

  public show(data: AnyObj) {
    this.visible = true
    this.data = data
  }

  private async confirm() {
    this.loading = true

    const { personId, id, faceUrl: imageUrl } = this.data
    const { data } = await this.$api.europa.addPersonImage(personId, { imageUrl })

    this.loading = false

    if (data.code === 0) {
      this.$emit('added', { id })
      this.visible = false
    }
  }

  private close() {
    this.visible = false
  }
}
