import ImageCompressor from 'image-compressor.js'
import EXIF from 'exif-js'

// 组件说明:该组件会对大于1MB的图片进行压缩，其他组件调用方法
// Orientation 参数的说明，获取照片的拍照方向
// 1   0°
// 6   顺时针90°
// 8   逆时针90°
// 3   180°

async function compressImg(file) {
  const option = {
    checkOrientation: true,
    quality: 0.8,
    maxWidth: 600,
    maxHeight: 600
  }
  const f = new ImageCompressor()
  if (file && file.size > 1048576) {
    try {
      await f.compress(file, option)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }
  let backFile = {}
  if (f.result) {
    backFile = f.result
  } else {
    backFile = file
  }
  const readFile = new FileReader()
  readFile.readAsDataURL(backFile)
  return readFile
}

function rotateImage(image) {
  const width = image.width
  const height = image.height
  let imageDate

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  let newImage = new Image()

  // 旋转图片操作
  EXIF.getData(image, function () {
    const orientation = EXIF.getTag(this, 'Orientation')
    switch (orientation) {
      // 正常状态
      case 1:
        newImage = image
        break
      // 旋转90度
      case 6:
        canvas.height = width
        canvas.width = height
        ctx.rotate(Math.PI / 2)
        ctx.translate(0, -height)
        ctx.drawImage(image, 0, 0)
        imageDate = canvas.toDataURL('Image/jpeg', 1)
        newImage.src = imageDate
        break
      case 3:
        canvas.height = height
        canvas.width = width
        ctx.rotate(Math.PI)
        ctx.translate(-width, -height)

        ctx.drawImage(image, 0, 0)
        imageDate = canvas.toDataURL('Image/jpeg', 1)
        newImage.src = imageDate
        break
      case 8:
        canvas.height = width
        canvas.width = height
        ctx.rotate(-Math.PI / 2)
        ctx.translate(-height, 0)

        ctx.drawImage(image, 0, 0)
        imageDate = canvas.toDataURL('Image/jpeg', 1)
        newImage.src = imageDate
        break
      default:
        newImage = image
        break
    }
  })
  return newImage
}

// getImage 为修改图片成功的回调函数，给组件付值应该在这个函数中src
function imageRotate(source, getImage) {
  const image = new Image()
  image.src = source
  image.onload = function () {
    const newImage = rotateImage(image)
    getImage(newImage.src)
  }
}

export { compressImg, imageRotate }
