



























































import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import PageTitle from '@/components/common/pageTitle.vue'
import SecurityText from '@/components/common/SecurityText/index.vue'
import ResidentDrawer from './ResidentDrawer.vue'
import ImageDrawer from './ImageDrawer.vue'
import pagination from '@/components/common/pagination/index.vue'
import Modal from '@/components/common/Modal'
import { record, is } from '@/utils/helpers'
import { areaPath } from '@/utils/getAreaPath'
import { tableScrollTop } from '@/utils/dom'
import asyncPool from '@/utils/async-pool'
import { axios } from '@/api'

const drawer = Modal(ResidentDrawer)

@Component({
  components: {
    PageTitle,
    SecurityText,
    ResidentDrawer,
    ImageDrawer,
    pagination
  }
})
export default class VisitorRecords extends Vue {
  @Prop({ default: 'unregistered' }) mode: 'registered' | 'unregistered'

  @Ref('imageDrawer') imageDrawer?: ImageDrawer
  @Ref('paginationNode') readonly paginationNode: pagination

  filterData: AnyObj = {
    dateRange: [this.$moment().startOf('day'), this.$moment().endOf('day')],
    limit: 20,
    offset: 0
  }
  selection: AnyObj[] = []
  pagination = { limit: 20, offset: 0 }
  personList: AnyObj[] = []
  infoCollLock = false
  loading = false
  dynamicIp?: string

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  get isRootAccount() {
    return this.root === this.$store.state.user.scope
  }

  get isRegistered() {
    return this.mode === 'registered'
  }

  get params() {
    const { areaIds, dateRange, limit, offset } = this.filterData
    const [startTime, endTime] = dateRange

    return Object.assign(
      { areaIds: [this.root], limit, offset, result: this.isRegistered },
      record({ areaIds, startTime, endTime }).filter(is.ava)
    )
  }

  mounted() {
    if (!this.dynamicIp) this.fetchIP()
    if (this.dynamicIp) {
      this.fetchPersonList()

      drawer.on('created', () => this.fetchPersonList())
    }
  }

  async fetchPersonList() {
    if (!this.dynamicIp) this.fetchIP()

    this.loading = true
    const { data } = await axios.get(
      `http://${this.dynamicIp}/bulk/person/info/search?limit=${this.filterData.limit}&offset=${this.filterData.offset}`
    )
    if (data.code === 200) {
      this.personList = data.data.map((item: AnyObj) => {
        Object.assign(item, areaPath(item.areas))
        return item
      })
      this.paginationNode.init({ total: data.count ? data.count : 0 })
      tableScrollTop()
    }
    this.loading = false
  }

  private fetchIP() {
    this.dynamicIp = localStorage.getItem('ga-ip') || undefined
  }

  private async pageChange(data: AnyObj) {
    this.filterData.limit = data.pageSize
    this.filterData.offset = (data.pageNo - 1) * data.pageSize
    await this.fetchPersonList()
  }

  getFilterData() {
    this.filterData.offset = 0
    this.paginationNode.initPage({ current: 1 })
    this.fetchPersonList()
  }

  onSelectionChange(selection: AnyObj[]) {
    this.selection = selection
  }

  async clickRegister(row: AnyObj) {
    this.loading = true

    const { identityNumber, faceUrl, id } = row
    const { data } = await this.$api.europa.getPersons({ identityNumber })

    this.loading = false

    if (data.data) {
      if (data.data.length > 0) {
        this.addImage({ ...data.data[0], id, faceUrl })
      } else {
        this.addResident(row)
      }
    }
  }

  async addImage(data: AnyObj) {
    this.imageDrawer?.show(data)
  }

  addResident(val: AnyObj) {
    drawer.open({
      mode: 'create',
      data: { ...val, areaId: this.root, imageUrl: val.faceUrl, description: val.address }
    })
  }

  async updateCollection(val: AnyObj) {
    if (this.mode === 'unregistered') {
      const { data } = await this.$api.base.updatePersonInfoCollection(val.id, {
        result: true
      })

      if (data.code === 0) {
        this.$emit('update')
      }
    }
  }

  clickDelete(info: AnyObj) {
    this.$confirm('删除后将无法恢复，是否确定删除？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      cancelButtonClass: 'message-cancel-btn'
    })
      .then(async () => {
        const resp = await this.$api.base.deletePersonInfoCollection(info.id)
        if (resp.status && resp.status === 204) {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.fetchPersonList()
        }
      })
      .catch(() => {})
  }

  async batchDelete() {
    this.$confirm('删除后将无法恢复，是否确定删除？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      cancelButtonClass: 'message-cancel-btn'
    }).then(async () => {
      this.loading = true

      await asyncPool(this.selection, ({ id }: AnyObj) =>
        this.$api.base.deletePersonInfoCollection(id)
      )
      await this.fetchPersonList()

      this.loading = false
    })
  }
}
